<template>
<div class="common-bg-member">
  <div class="middle common-padding-half">
    <div class="result">
      <div class="result-c">
        <h1>註冊失敗</h1>
        <img src="@/assets/img/pic15.png">
        <p>網絡斷開原因，註冊失敗</p>
      </div>
      <div class="back-btn">
        <router-link to="/register">
          返回註冊
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Fail"
}
</script>

<style scoped>

</style>